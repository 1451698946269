import ReactDOM from 'react-dom';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import frFR from 'antd/lib/locale/fr_FR';
import { createBrowserHistory } from 'history';
import countries from 'i18n-iso-countries';
import i18nIsoCountryEN from 'i18n-iso-countries/langs/en.json';
import i18nIsoCountryFR from 'i18n-iso-countries/langs/fr.json';

import 'moment/locale/fr';

import { apolloClient } from '@graphql/client';

import { AdminCommentsDrawerProvider } from '@hooks/adminCommentsDrawer/adminCommentsDrawer.hooks';
import { AuthProvider } from '@hooks/auth/auth.hooks';

import './index.less';

import { App } from './App';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';

countries.registerLocale(i18nIsoCountryFR);
countries.registerLocale(i18nIsoCountryEN);

const locales = i18n.options.supportedLngs as string[];
const base = `/:locale(${locales.join('|')})`;

const history = createBrowserHistory();

ReactDOM.render(
  // <React.StrictMode> Strict mode disabled because of antd
  <Router history={history}>
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <ConfigProvider locale={frFR} form={{ requiredMark: 'optional' }}>
          <AdminCommentsDrawerProvider>
            <Switch>
              <Route path={base} component={App} />
              <Redirect to={`/${i18n.options.fallbackLng}`} />
            </Switch>
          </AdminCommentsDrawerProvider>
        </ConfigProvider>
      </AuthProvider>
    </ApolloProvider>
  </Router>, // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
